















































































































































import { defineComponent, ref, onMounted, computed, toRefs, watch } from '@vue/composition-api'
import { showError, endpoints, toCamelCase, toSnakeCase } from 'utils'
import { api } from 'plugins'
import { SelectInputRow, ChooseNumber } from 'components'
import { snakeCase } from 'lodash'
import moment from 'moment'
import RemarkDialog from './RemarkDialog.vue'

const ClaimHandlingDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    claim: {
      type: Object,
      required: true
    }
  },
  components: {
    SelectInputRow,
    ChooseNumber,
    RemarkDialog
  },
  setup(props, { root, emit }) {
    const { $toast } = root
    const { claim } = toRefs(props)
    const growerSelected = ref<number[]>([])
    const growerParent = ref<any>()
    const claimsHandling = ref<any>([])
    const listStates = ['handlingDate', 'claimStems', 'claimAmount', 'remark']
    const setPropertyName = ref('')
    const numberDialog = ref(false)
    const isShowDateDialog = ref(false)
    const date = ref(moment(new Date()).format('YYYY-MM-DD'))
    const currentGrower = ref(0)
    const handling = ref<any>([])
    const growerDelete = ref<number[]>([])
    const showEditRemark = ref(false)
    const remarkValue = ref<string>('')

    const numberDialogTitle = computed((): string => {
      return root.$t(`claim.${snakeCase(setPropertyName.value)}`) as string
    })

    const getIndexCurrentGrower = () => {
      return claimsHandling.value.map((e: any) => e.grower.id).indexOf(currentGrower.value)
    }

    const numberDialogValue = computed(() => {
      // find index grower
      const indexGrower = getIndexCurrentGrower()
      return claimsHandling.value[indexGrower][setPropertyName.value] || 0
    })

    const chooseProperty = (property: string) => {
      setPropertyName.value = property
      numberDialog.value = false
      isShowDateDialog.value = false

      switch (property) {
        // handlingDate
        case 'handlingDate':
          isShowDateDialog.value = true
          break
        case 'claimStems':
          numberDialog.value = true
          break
        case 'claimAmount':
          numberDialog.value = true
          break
        case 'remark':
          remarkValue.value = claimsHandling.value[getIndexCurrentGrower()].remark
          remarkValue.value = remarkValue.value ? remarkValue.value : ''
          showEditRemark.value = true
          break
        default:
      }
    }

    const nextProperty = computed(() => {
      const key = setPropertyName.value
      const indexKey = listStates.indexOf(key) + 1
      return listStates[indexKey]
    })

    const prevProperty = computed(() => {
      const key = setPropertyName.value
      const indexKey = listStates.indexOf(key) - 1
      return listStates[indexKey]
    })

    const selectProperty = (action: string): void => {
      if (action === 'next') {
        chooseProperty(nextProperty.value)
      } else if (action === 'prev') {
        chooseProperty(prevProperty.value)
      }
    }

    const getGrowerParent = async () => {
      try {
        const { data } = await api.get(`${endpoints.GROWER}get_parent`)
        growerParent.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const handleSelectGrower = (packingImport: any) => {
      // check is exists
      const indexGrowerSelected = growerSelected.value.indexOf(packingImport.grower.id)
      if (indexGrowerSelected === -1) {
        // growers selected check box
        growerSelected.value.push(packingImport.grower.id)
        const claimHandled = handling.value.find((e: any) => e.packingImportId === packingImport.id)
        // check this claim handled ?
        if (claimHandled) {
          claimHandled.grower = packingImport.grower
          claimsHandling.value.push(claimHandled)
        } else {
          // if not yet handle -> push default data
          claimsHandling.value.push({
            id: null,
            claim_id: claim.value.id,
            packingImportId: packingImport.id,
            claimStems: null,
            claimAmount: null,
            handlingDate: moment(new Date()).format('YYYY-MM-DD'),
            remark: null,
            grower: packingImport.grower
          })
        }
        if (growerDelete.value.indexOf(packingImport.grower.id) !== -1)
          growerDelete.value.splice(growerDelete.value.indexOf(packingImport.grower.id), 1)
      } else {
        growerSelected.value.splice(indexGrowerSelected, 1)
        claimsHandling.value.splice(indexGrowerSelected, 1)
        if (growerDelete.value.indexOf(packingImport.grower.id) === -1)
          growerDelete.value.push(packingImport.grower.id)
      }
    }

    const onSave = async () => {
      // get body
      let body = JSON.parse(JSON.stringify(claimsHandling.value))
      let isCallAPI = false
      body = body.map((e: any) => {
        delete e.grower
        return e
      })
      const bodyUpdate = body.filter((e: any) => e.id)
      const bodyCreate = body.filter((e: any) => !e.id)
      const bodyDelete: number[] = []
      growerDelete.value.forEach((grower: number) => {
        const handleId = handling.value.find((handle: any) => handle.grower.id === grower)?.id
        if (handleId && bodyDelete.indexOf(handleId) === -1) bodyDelete.push(handleId)
      })

      try {
        if (bodyCreate.length > 0) {
          isCallAPI = true
          await api.post(`${endpoints.CLAIM_HANDLING}create_multiple`, toSnakeCase(bodyCreate))
        }
        if (bodyUpdate.length > 0) {
          isCallAPI = true
          await api.put(`${endpoints.CLAIM_HANDLING}update_multiple`, toSnakeCase(bodyUpdate))
        }
        if (bodyDelete.length > 0) {
          isCallAPI = true
          await api.delete(
            `${endpoints.CLAIM_HANDLING}delete_multiple?ids=${bodyDelete.toString()}`
          )
        }
        if (isCallAPI) {
          emit('re-load')
        }
        $toast.success(root.$t('claim.msg.update_successful'))
        emit('on-close')
      } catch (e) {
        showError(e, $toast, root.$t('claim.msg.update_failed') as string)
      }
    }

    const updateClaimHandle = (action: string, item: number | string): void => {
      // find index grower
      const indexGrower = getIndexCurrentGrower()
      // console.log(claimsHandling.value[indexGrower])
      console.log(setPropertyName.value)
      claimsHandling.value[indexGrower][setPropertyName.value] = item
      console.log(claimsHandling.value)
      selectProperty(action)
    }

    const updateRemark = (data: any) => {
      showEditRemark.value = false
      const indexGrower = getIndexCurrentGrower()
      claimsHandling.value[indexGrower].remark = data.value?.trim()
      remarkValue.value = ''
    }

    const getHandlingAPI = async () => {
      // console.log(claim.value)
      try {
        const { data } = await api.get(`${endpoints.CLAIM_HANDLING}?claim_id=${claim.value.id}`)
        handling.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
      // add parent grower in list grower
      claimsHandling.value = handling.value.map((handle: any) => {
        if (handle.packingImportId) {
          const { grower } = claim.value.packingImportDetails.find(
            (e: any) => e.id === handle.packingImportId
          )
          return { ...handle, grower }
        }
        return { ...handle, grower: growerParent.value }
      })
      handling.value = JSON.parse(JSON.stringify(claimsHandling.value))
      // auto select grower if exists
      growerSelected.value = claimsHandling.value.map((e: any) => e.grower?.id)

      if (
        !claim.value.packingImportDetails.find((e: any) => e.grower.id === growerParent.value.id)
      ) {
        claim.value.packingImportDetails.unshift({
          grower: growerParent.value,
          id: null
        })
      }
    }

    watch(
      () => props.value,
      () => {
        if (props.value) getHandlingAPI()
      }
    )

    onMounted(getGrowerParent)

    return {
      growerSelected,
      growerParent,
      handleSelectGrower,
      claimsHandling,
      chooseProperty,
      numberDialog,
      setPropertyName,
      numberDialogTitle,
      isShowDateDialog,
      selectProperty,
      date,
      updateClaimHandle,
      currentGrower,
      numberDialogValue,
      onSave,
      showEditRemark,
      remarkValue,
      updateRemark
    }
  }
})

export default ClaimHandlingDialog
