var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.assign).length > 0)?_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"450","value":_vm.value}},[_c('v-card',{staticClass:"white-bg"},[_c('v-card-title',{staticClass:"rough_black px-2 py-2"},[_c('div',{staticClass:"white--text",staticStyle:{"width":"100%"}},[_c('span',[_vm._v(_vm._s(_vm.mode === 'create' ? _vm.$t('claim_register.title') : _vm.$t('claim.edit_claim')))]),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('on-close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('br'),_c('span',[_vm._v(_vm._s(_vm.moment(_vm.assign.auctionDate).format(_vm.$t('common.time_month_date'))))])],1)]),_c('div',[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-1",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.assign.productName))])])],1),_c('v-row',{staticClass:"my-0 mx-1 py-0 px-1",staticStyle:{"text-align":"left","background":"lightgrey","font-size":"14px"}},[_c('v-col',{staticClass:"ma-0 pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.boxes'))+":")]),_c('span',{staticClass:"bold--text"},[_vm._v(_vm._s(_vm.assign.salesBoxes))])]),_c('v-col',{staticClass:"ma-0 pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.claim_stems_short'))+":")]),_c('span',{staticClass:"bold--text"},[_vm._v(_vm._s(_vm.assign.salesStems)+" "+_vm._s(_vm.assign.unit.name))])]),_c('v-col',{staticClass:"ma-0 pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.unit'))+":")]),_c('span',{staticClass:"bold--text"},[_vm._v(_vm._s(_vm.assign.salesPrice))])]),_c('v-col',{staticClass:"ma-0 pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.claim_amount_short'))+":")]),_c('span',{staticClass:"bold--text"},[_vm._v(_vm._s(_vm.assign.salesAmount))])])],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"ma-1 pa-0",staticStyle:{"text-align":"center","border":"1px solid lightgrey"}},[_c('v-col',{staticClass:"ma-0 pa-0 pointer",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 px-1 py-0",staticStyle:{"border-bottom":"1px solid lightgrey"}},[_c('v-col',{staticClass:"ma-0 px-1 align-left",attrs:{"cols":"5"}},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.claim_date')))])]),_c('v-col',{staticClass:"ma-0 px-1 py-0 align-right",attrs:{"cols":"7"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('claim.claim_date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-dialog',{ref:"claimDateDialog",attrs:{"persistent":"","width":"90vw","return-value":_vm.date},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","hide-details":"","reverse":"","flat":"","solo":"","value":_vm.moment(_vm.date).format(_vm.$t('common.time_month_date'))},on:{"click":function($event){return _vm.onChangeTextField('claim_date')}}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.claimDateModal),callback:function ($$v) {_vm.claimDateModal=$$v},expression:"claimDateModal"}},[_c('v-date-picker',{attrs:{"full-width":"","locale":"ja-jp","color":"rough_black","header-color":"rough_black"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"dark":"","text":"","color":"light_red"},on:{"click":function($event){_vm.claimDateModal = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.cancel')))])]),_c('v-btn',{staticClass:"white--text",attrs:{"dark":"","text":"","color":"rough_black"},on:{"click":function($event){_vm.$refs.claimDateDialog.save(_vm.date), _vm.goToDate()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.ok')))])])],1)],1)]}}],null,true)})],1)],1)],1),_c('v-col',{staticClass:"ma-0 pa-0 pointer",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 px-1 py-0",staticStyle:{"border-bottom":"1px solid lightgrey"}},[_c('v-col',{staticClass:"ma-0 px-1 align-left",attrs:{"cols":"5"}},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.actual_sales_stems')))])]),_c('v-col',{staticClass:"ma-0 px-1 py-0 align-right",attrs:{"cols":"7"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('claim.actual_sales_stems')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('claim.actual_sales_stems'),"value":_vm.claim.actualSalesStems,"flat":"","solo":"","reverse":"","readonly":"","hide-details":""},on:{"click":function($event){return _vm.onChangeTextField('actual_sales_stems')}}})]}}],null,true)})],1)],1)],1),_c('v-col',{staticClass:"ma-0 pa-0 pointer",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 px-1 py-0",staticStyle:{"border-bottom":"1px solid lightgrey"}},[_c('v-col',{staticClass:"ma-0 px-1 align-left",attrs:{"cols":"5"}},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.actual_sales_amount')))])]),_c('v-col',{staticClass:"ma-0 px-1 py-0 align-right",attrs:{"cols":"7"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('claim.actual_sales_amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('claim.actual_sales_amount'),"value":_vm.claim.actualSalesAmount,"flat":"","solo":"","reverse":"","readonly":"","hide-details":""},on:{"click":function($event){return _vm.onChangeTextField('actual_sales_amount')}}})]}}],null,true)})],1)],1)],1),_c('v-col',{staticClass:"ma-0 pa-0 pointer",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 px-1 py-0",staticStyle:{"border-bottom":"1px solid lightgrey"}},[_c('v-col',{staticClass:"ma-0 px-1 align-left",attrs:{"cols":"5"}},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.remark')))])]),_c('v-col',{staticClass:"ma-0 px-1 py-0 align-right",attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('claim.remark'),"value":_vm.claim.remark,"flat":"","solo":"","reverse":"","readonly":"","hide-details":""},on:{"click":function($event){return _vm.onChangeTextField('remark')}}})],1)],1)],1),_c('v-col',{staticClass:"ma-0 pa-0 pointer",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 px-1 py-0",staticStyle:{"border-bottom":"1px solid lightgrey"}},[_c('v-col',{staticClass:"ma-0 px-1 align-left",attrs:{"cols":"5"}},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.claim_stems')))])]),_c('v-col',{staticClass:"ma-0 px-1 py-0 align-right",attrs:{"cols":"7"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('claim.claim_stems')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('claim.claim_stems'),"value":_vm.claim.claimStems,"flat":"","solo":"","reverse":"","readonly":"","hide-details":""},on:{"click":function($event){return _vm.onChangeTextField('claim_stems')}}})]}}],null,true)})],1)],1)],1),_c('v-col',{staticClass:"ma-0 pa-0 pointer",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 px-1 py-0"},[_c('v-col',{staticClass:"ma-0 px-1 align-left",attrs:{"cols":"5"}},[_c('span',[_vm._v(_vm._s(_vm.$t('claim.claim_amount')))])]),_c('v-col',{staticClass:"ma-0 px-1 py-0 align-right",attrs:{"cols":"7"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('claim.claim_amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('claim.claim_amount'),"value":_vm.claim.claimAmount !== null ? (-_vm.claim.claimAmount).toLocaleString() : null,"flat":"","solo":"","reverse":"","readonly":"","hide-details":""},on:{"click":function($event){return _vm.onChangeTextField('claim_amount')}}})]}}],null,true)})],1)],1)],1)],1)]}}],null,false,965555060)})],1),_c('v-card-actions',{staticClass:"pb-0"},[_c('v-row',{staticClass:"mb-1",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"relative-btn ma-2 white--text",attrs:{"large":!_vm.$vuetify.breakpoint.xsOnly,"color":"light_red","width":"40%"},on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),(_vm.mode === 'create')?_c('v-btn',{staticClass:"relative-btn ma-2 white--text",attrs:{"large":!_vm.$vuetify.breakpoint.xsOnly,"color":"rough_black","width":"40%"},on:{"click":function($event){return _vm.onCreate()}}},[_vm._v(_vm._s(_vm.$t('common.save')))]):_vm._e(),(_vm.mode === 'edit')?_c('v-btn',{staticClass:"relative-btn ma-2 white--text",attrs:{"large":!_vm.$vuetify.breakpoint.xsOnly,"color":"rough_black","width":"40%"},on:{"click":function($event){return _vm.onEdit()}}},[_vm._v(_vm._s(_vm.$t('common.save')))]):_vm._e()],1)],1),_c('choose-number',{attrs:{"title":_vm.$t('claim.' + _vm.currentTitle),"inputValue":_vm.currentInput || '',"label":_vm.currentLabel},on:{"input":function($event){_vm.chooseNumberModal = false},"on-change":function (action, code) { return _vm.editDataClaim(action, code); }},model:{value:(_vm.chooseNumberModal),callback:function ($$v) {_vm.chooseNumberModal=$$v},expression:"chooseNumberModal"}}),_c('remark-dialog',{attrs:{"value":_vm.showEditRemark,"remark":_vm.remarkValue},on:{"on-close":function (event) { return _vm.onCloseRemarkDialog(event); },"on-ok":function (event) { return _vm.updateRemark(event); }}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }