

































import { defineComponent, ref, watch } from '@vue/composition-api'

const RemarkDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    remark: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const changedRemark = ref('')
    const updateRemark = (type: string) => {
      const output = {
        type,
        value: changedRemark.value
      }
      emit('on-ok', output)
    }
    const onTyping = (event: any) => {
      if (event.keyCode === 27) {
        // Press ESC
        updateRemark('esc')
        emit('on-close', changedRemark.value)
      } else if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        updateRemark('next')
      } else if (event.keyCode === 13 && event.shiftKey) {
        // Press shift + Enter
        updateRemark('prev')
      }
    }
    watch(
      () => props.value,
      () => {
        if (props.value) changedRemark.value = props.remark
      }
    )

    return {
      changedRemark,
      updateRemark,
      onTyping
    }
  }
})

export default RemarkDialog
