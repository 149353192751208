




















































































































































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { ChooseNumber } from 'components'
import RemarkDialog from './RemarkDialog.vue'
import { moment } from '../../plugins'

const ClaimRegisterDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    assign: {
      type: Object,
      required: true
    },
    editClaim: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  components: {
    ChooseNumber,
    RemarkDialog
  },
  setup(props, { root, refs, emit }) {
    const { $toast, $route, $store } = root
    const chooseNumberModal = ref<boolean>(false)
    const claimDateModal = ref(false)
    const date = ref<string>(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.aucDate || moment(new Date()).format('YYYY-MM-DD')
    )
    const claim = ref<any>({
      claimDate: date.value,
      actualSalesStems: null,
      actualSalesAmount: null,
      remark: null,
      claimStems: null,
      claimAmount: null
    })
    const currentTitle = ref<string>('')
    const currentInput = ref<any>(null)
    const currentLabel = ref<string>('')
    const showEditRemark = ref<boolean>(false)
    const remarkValue = ref<string>('')
    const validate = async (): Promise<boolean> => {
      const observer = refs.observer as InstanceType<typeof ValidationObserver>
      const valid = await observer.validate()
      if (!valid) {
        let errorMess: Array<string> = []
        Object.keys(observer.errors).forEach((key) => {
          errorMess = [...errorMess, ...observer.errors[key]]
        })
        $toast.error(errorMess.join(',\n'))
        return false
      }
      return true
    }
    const onCreate = async () => {
      if (await validate()) {
        emit('on-create', claim.value)
        claim.value = {
          claimDate: date.value,
          actualSalesStems: null,
          actualSalesAmount: null,
          remark: null,
          claimStems: null,
          claimAmount: null
        }
      }
    }
    const onEdit = async () => {
      if (await validate()) {
        emit('on-edit', claim.value)
      }
    }
    const onChangeTextField = (inputType: string) => {
      switch (inputType) {
        case 'claim_date':
          chooseNumberModal.value = false
          claimDateModal.value = true
          break
        case 'actual_sales_stems':
          claimDateModal.value = false
          chooseNumberModal.value = true
          currentLabel.value = 'actual_sales_stems'
          currentTitle.value = 'actual_sales_stems'
          currentInput.value = claim.value.actualSalesStems
          break
        case 'actual_sales_amount':
          showEditRemark.value = false
          chooseNumberModal.value = true
          currentLabel.value = 'actual_sales_amount'
          currentTitle.value = 'actual_sales_amount'
          currentInput.value = claim.value.actualSalesAmount
          break
        case 'claim_stems':
          showEditRemark.value = false
          chooseNumberModal.value = true
          currentLabel.value = 'claim_stems'
          currentTitle.value = 'claim_stems'
          currentInput.value = claim.value.claimStems
          break
        case 'claim_amount':
          showEditRemark.value = false
          chooseNumberModal.value = true
          currentLabel.value = 'claim_amount'
          currentTitle.value = 'claim_amount'
          currentInput.value = claim.value.claimAmount
          break
        case 'remark':
          chooseNumberModal.value = false
          showEditRemark.value = true
          break
        default:
          break
      }
    }
    const editDataClaim = (action: string, code: number) => {
      switch (currentTitle.value) {
        case 'actual_sales_stems':
          claim.value.actualSalesStems = code !== 0 ? code : null
          if (action === 'next') onChangeTextField('actual_sales_amount')
          if (action === 'prev') onChangeTextField('claim_date')
          break
        case 'actual_sales_amount':
          claim.value.actualSalesAmount = code !== 0 ? code : null
          if (action === 'next') onChangeTextField('remark')
          if (action === 'prev') onChangeTextField('actual_sales_stems')
          break
        case 'claim_stems':
          claim.value.claimStems = code !== 0 ? code : null
          if (action === 'next') onChangeTextField('claim_amount')
          if (action === 'prev') onChangeTextField('remark')
          break
        case 'claim_amount':
          claim.value.claimAmount = code !== 0 ? code : null
          if (action === 'next') chooseNumberModal.value = false
          if (action === 'prev') onChangeTextField('claim_stems')
          break
        default:
          break
      }
    }
    const goToDate = () => {
      claim.value.claimDate = date.value
      onChangeTextField('actual_sales_stems')
    }
    const updateRemark = (remark: any) => {
      claim.value.remark = remark.value
      remarkValue.value = remark.value
      if (remark.type === 'prev') onChangeTextField('actual_sales_amount')
      if (remark.type === 'next') onChangeTextField('claim_stems')
    }
    const onCloseRemarkDialog = (event: any) => {
      showEditRemark.value = false
      claim.value.remark = event
    }

    const onTyping = (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        // eslint-disable-next-line no-unused-expressions
        props.mode === 'edit' ? onEdit() : onCreate()
      }
    }

    watch(props, () => {
      if (props.assign) {
        switch (props.mode) {
          case 'edit':
            claim.value = {
              claimDate: props.editClaim.claimDate,
              actualSalesStems: props.editClaim.actualSalesStems,
              actualSalesAmount: props.editClaim.actualSalesAmount,
              remark: props.editClaim.remark,
              claimStems: props.editClaim.claimStems,
              claimAmount: props.editClaim.claimAmount
            }
            remarkValue.value = claim.value.remark
            break
          case 'create':
            claim.value = {
              claimDate: date.value,
              actualSalesStems: null,
              actualSalesAmount: null,
              remark: null,
              claimStems: null,
              claimAmount: null
            }
            remarkValue.value = ''
            break
          default:
            break
        }
      }
      if (props.value) {
        document.addEventListener('keydown', onTyping)
      } else {
        document.removeEventListener('keydown', onTyping)
      }
    })
    return {
      claim,
      chooseNumberModal,
      date,
      claimDateModal,
      currentTitle,
      currentLabel,
      currentInput,
      showEditRemark,
      remarkValue,
      updateRemark,
      onCreate,
      editDataClaim,
      goToDate,
      onChangeTextField,
      onCloseRemarkDialog,
      onEdit
    }
  }
})

export default ClaimRegisterDialog
